import { useEffect, useState } from 'react';
import { SummaryComponentProps } from '../components/summary';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import {
  getAllUserInvoices,
  updateInvoiceStatus,
} from '../../../../services/invoices';

export const useGetInvoices = () => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [summaryComponent, setSummaryComponent] =
    useState<SummaryComponentProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setSummaryComponent({
      total: {
        amount: 0,
        numInvoices: 0,
      },
      dueToday: {
        amount: 0,
        numInvoices: 0,
      },
      overdue: {
        amount: 0,
        numInvoices: 0,
      },
    });
    fetchInvoices().then(() => {
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (invoices) {
      const total = invoices
        .filter((x) => {
          return x.status !== 'PAID';
        })
        .reduce(
          (acc, invoice) => {
            return {
              amount: acc.amount + parseFloat(invoice.amount),
              numInvoices: acc.numInvoices + 1,
            };
          },
          { amount: 0, numInvoices: 0 }
        );

      const dueToday = invoices
        .filter((x) => {
          return (
            x.status !== 'PAID' &&
            (dayjs(x.dueDate).isSame(dayjs(), 'day') ||
              dayjs(x.dueDate).isBefore(dayjs()))
          );
        })
        .reduce(
          (acc, invoice) => {
            return {
              amount: acc.amount + parseFloat(invoice.amount),
              numInvoices: acc.numInvoices + 1,
            };
          },
          { amount: 0, numInvoices: 0 }
        );

      const overdue = invoices
        .filter((x) => {
          return x.status !== 'PAID' && dayjs(x.dueDate).isBefore(dayjs());
        })
        .reduce(
          (acc, invoice) => {
            return {
              amount: acc.amount + parseFloat(invoice.amount),
              numInvoices: acc.numInvoices + 1,
            };
          },
          { amount: 0, numInvoices: 0 }
        );

      setSummaryComponent({
        total,
        dueToday,
        overdue,
      });
    }
  }, [invoices]);

  async function fetchInvoices() {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);

      const response = await getAllUserInvoices(accessToken);
      setInvoices(response);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setLoading(false);
    }
  }

  return { accessToken, invoices, summaryComponent, loading, loaded };
};

export const useUpdateInvoice = () => {
  const { getAccessTokenSilently } = useAuth0();

  const updateInvoice = async (invoiceId: string, status: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      await updateInvoiceStatus(accessToken, invoiceId, status);
    } catch (error) {
      console.error('Error updating invoice:', error);
    }
  };

  return { updateInvoice };
};
