import dayjs from 'dayjs';

export const getStatusFromDate = (date: string, status: string): string => {
  if (status === 'PAID') {
    return 'PAID';
  }
  const dueDate = dayjs(date);
  const today = dayjs();
  if (dueDate.isBefore(today)) {
    return 'OVERDUE';
  }
  if (dueDate.isSame(today, 'day')) {
    return 'DUE TODAY';
  }
  return 'OUTSTANDING';
};
