import { Box, Text, useToast } from '@chakra-ui/react';
import { SummaryComponent, SummaryComponentProps } from './components/summary';
import { InvoicesTable, InvoicesTableProps } from './components/invoicesTable';
import { useGetInvoices, useUpdateInvoice } from './hooks/index';
import { useState } from 'react';
import { Invoice } from './index.types';
import InvoiceDetailsModal from './components/invoiceDetailsModal';

export const InvoicesDashboardPage = () => {
  const { accessToken, invoices, summaryComponent, loaded } = useGetInvoices();
  const { updateInvoice } = useUpdateInvoice();
  const toast = useToast();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);

  console.log('invoices', invoices);

  const handleSetSelectedInvoice = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
  };

  const handleMarkAsPaid = async (amount: number) => {
    console.log('marking as paid');
    await updateInvoice(selectedInvoice.id, 'PAID');
    setSelectedInvoice(null);
    toast({
      title: 'Invoice updated',
      description: `The selected invoice has been marked as paid`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  if (!loaded) {
    return (
      <Box>
        <Text>Loading...</Text>
      </Box>
    );
  }

  return (
    <>
      <Box mb={'4rem'}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          Invoices dashboard
        </Text>
      </Box>
      {summaryComponent && (
        <SummaryComponent
          dueToday={summaryComponent.dueToday}
          overdue={summaryComponent.overdue}
          total={summaryComponent.total}
        />
      )}
      <InvoicesTable
        invoices={invoices}
        setSelectedInvoice={handleSetSelectedInvoice}
      />
      {selectedInvoice && (
        <InvoiceDetailsModal
          invoice={selectedInvoice}
          onClose={() => setSelectedInvoice(null)}
          onMarkAsPaid={handleMarkAsPaid}
          accessToken={accessToken}
        />
      )}
    </>
  );
};
