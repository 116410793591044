import { callGet, callPatch, callPost } from 'lib/api-private';
import { saveProps } from 'views/admin/pendingInvoices/components/SelectedInvoiceEditModal';

export const getAllUnprocessedInvoices = async (accessToken: string) => {
  const response = await callGet(accessToken, '/invoices/unprocessed');
  return response;
};

export const getAllUserInvoices = async (accessToken: string) => {
  const response = await callGet(accessToken, '/invoices');
  return response;
};

export const updateInvoiceStatus = async (
  accessToken: string,
  invoiceId: string,
  status: string
) => {
  const response = await callPatch(accessToken, `/invoices/${invoiceId}`, {
    status,
  });
  return response;
};

export const createInvoice = async (accessToken: string, props: saveProps) => {
  const response = await callPost(accessToken, '/invoices', props);
  return response;
};
