import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Invoice } from '../index.types';
import { getStatusFromDate } from '../utils';
import { getFileLink } from 'services/file';

interface InvoiceDetailsModalProps {
  onClose: () => void;
  onMarkAsPaid: (amount: number) => void;
  invoice: Invoice;
  accessToken: string;
}

const InvoiceDetailsModal: React.FC<InvoiceDetailsModalProps> = ({
  onClose,
  invoice,
  onMarkAsPaid,
  accessToken,
}) => {
  const [resourceUrl, setResourceUrl] = React.useState<string | null>(null);
  const [mimeType, setMimeType] = React.useState<string | null>(null);
  const [fileKey, setFileKey] = React.useState<string | null>(null);

  useEffect(() => {
    if (!invoice) {
      return;
    }
    if (invoice.files && invoice.files.length > 0) {
      const fileKey = invoice.files[0].key;
      const mimeType = invoice.files[0].mimeType;
      setFileKey(fileKey);
      setMimeType(mimeType);
    }
  }, [invoice]);

  useEffect(() => {
    if (!fileKey || !accessToken) {
      return;
    }
    console.log('getting file link');
    getFileLink(fileKey, accessToken)
      .then((response) => {
        setResourceUrl(response);
      })
      .catch((error) => {
        console.log('error getting file link:', error);
      });
  }, [fileKey, accessToken]);

  // console.log('resource url', resourceUrl, fileKey, accessToken);

  return (
    <Modal isOpen={true} onClose={onClose} size={fileKey ? '6xl' : 'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {invoice.supplierName}
          <hr />
        </ModalHeader>
        {/* <ModalCloseButton /> */}

        <ModalBody>
          <Flex gap={'2rem'}>
            {fileKey && (
              <Box w='100%'>
                {mimeType === 'application/pdf' && (
                  <iframe
                    src={resourceUrl}
                    width='100%'
                    height='500px'
                    title='document preview'
                  />
                )}
                {mimeType === 'application/img' && (
                  <img
                    src={resourceUrl}
                    alt='Document Preview'
                    style={{ maxWidth: '100%', maxHeight: '500px' }}
                  />
                )}
              </Box>
            )}
            <Box mb='2rem' w='50%'>
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                mb={'1rem'}
              >
                <Flex direction={'column'}>
                  <Text fontSize={'2xl'} fontWeight={'bold'}>
                    R {invoice.amount}
                  </Text>
                  <Text>Invoice Number: {invoice.supplierInvoiceId}</Text>
                </Flex>

                {invoice.status !== 'PAID' && (
                  <Flex direction={'column'} gap={'0.25rem'}>
                    <Button variant={'outline'} onClick={() => onMarkAsPaid(0)}>
                      Mark as Paid
                    </Button>
                    <Button variant={'outline'}>Cancel</Button>
                  </Flex>
                )}
              </Flex>
              <Box mb={'1rem'}>
                <hr />
              </Box>

              <Flex justifyContent={'space-between'}>
                <Text>Status</Text>
                <Text>
                  {getStatusFromDate(invoice.dueDate, invoice.status)}
                </Text>
              </Flex>

              <Flex justifyContent={'space-between'}>
                <Text>Due date</Text>
                <Text>{dayjs(invoice.dueDate).format('MMM DD')}</Text>
              </Flex>
            </Box>
          </Flex>
        </ModalBody>
        {/* <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default InvoiceDetailsModal;
